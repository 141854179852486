<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterRedirects">
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <app-input
                v-model="filter.sourceUrl"
                id="filter_sourceUrl"
                :label="$t('redirect.filter.source_url')"
              >
              </app-input>
            </div>
            <div class="col-lg-3 col-md-6">
              <app-input
                v-model="filter.destinationUrl"
                id="filter_destinationUrl"
                :label="$t('redirect.filter.destination_url')"
              >
              </app-input>
            </div>
            <div class="col-lg-1 col-md-6">
              <app-input
                v-model="filter.id"
                id="filter_id"
                :label="$t('redirect.filter.id')"
              >
              </app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-select
                v-model="filter.site"
                :options="sites"
                :label="$t('redirect.filter.site')"
                id="filter_site"
                disable-form-group
              >
              </app-select>
            </div>
            <div class="col-lg-3">
              <button class="btn btn-success pull-right m-t-30">
                {{ $t('redirect.filter.search') }}
              </button>
              <button
                type="button"
                class="btn btn-secondary m-t-30"
                data-test="reset_filter"
                @click="resetFilter"
              >
                {{ $t('buttons.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import Input from '../form/inputs/Input'
import Select from '../form/select/Select'
import RedirectFilter from '../../model/RedirectFilter'

export default {
  name: 'RedirectFilter',
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(RedirectFilter)
    }
  },
  computed: {
    sites () {
      return this.$store.getters['site/enabledSites']()
    }
  },
  components: {
    appInput: Input,
    appSelect: Select
  },
  methods: {
    filterRedirects () {
      this.$store.commit('redirect/setPage', 1)
      this.$store.commit('redirect/setFilter', this.filter)
      this.$store.dispatch('redirect/fetch')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(RedirectFilter)
      this.$store.commit('redirect/setFilter', this.filter)
      this.$store.dispatch('redirect/fetch')
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['redirect/filter']
  }
}
</script>
