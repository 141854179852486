<template>
  <app-module-view>
    <template slot="buttons">
      <app-button-create
        route-name="redirect_new"
      >
      </app-button-create>
    </template>
    <template slot="body">
      <app-redirect-filter></app-redirect-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="redirects"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import { mapState } from 'vuex'
import Preloader from '../../components/preloader/Preloader'
import RedirectFilter from '../../components/filter/FilterRedirect'
import DataTable from '../../components/shared/DataTable'
import RedirectListService from '../../services/redirect/RedirectListService'
import ButtonCreate from '../../components/shared/ButtonCreate'

export default {
  name: 'RedirectListView',
  data () {
    return {
      dataLoaded: false,
      dataTableConfig: {
        fields: {
          id: this.$t('redirect.list.id'),
          site: this.$t('redirect.list.site'),
          sourceUrl: this.$t('redirect.list.source_url'),
          destinationUrl: this.$t('redirect.list.destination_url')
        },
        actions: {
          edit: 'redirect_edit'
        },
        render: {
          site: function (data) {
            if (data) {
              return RedirectListService.getSiteTitle(data)
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    redirects () {
      return this.$store.getters['redirect/list']
    },
    totalCount () {
      return this.$store.getters['redirect/totalCount']
    },
    page () {
      return this.$store.getters['redirect/page']
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appRedirectFilter: RedirectFilter,
    appDataTable: DataTable,
    appButtonCreate: ButtonCreate
  },
  methods: {
    getRedirects () {
      this.$store.dispatch('redirect/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('redirect/setPage', page)
      this.getRedirects()
    }
  },
  mounted () {
    this.getRedirects()
  }
}
</script>

<style lang="scss" scoped>

.table-responsive {
  th, td {
    font-size: 14px
  }
}

</style>
